<template>
  <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
          <div>
            <div class="mt-3 text-center sm:mt-5">
              <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Tap to mark the defect area</h3>
              <div id="mainHandler">
                <div id="imageHandler">
                  <!-- Canvas Element -->
                  <div style="width: 100%; max-width: 800px;">
                    <!-- Resized Canvas -->
                    <canvas
                        ref="canvas"
                        @click="addDot"
                        style="border: 1px solid #000; width: 100%; height: auto;"
                    ></canvas>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
            <button
                type="button"
                class="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 sm:col-start-2"
                @click="saveImage"
                v-if="isCreate"
            >
              Save
            </button>
            <div class="columns-2">
              <div>
                <button
                    type="button"
                    class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    @click="hidePreview"
                >
                  {{ isCreate ? 'Cancel' : 'Close'}}
                </button>
              </div>
              <div>
                <button
                    type="button"
                    class="mt-3 inline-flex w-full justify-center rounded-md bg-red-700 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-red-300 hover:bg-red-700 sm:col-start-1 sm:mt-0"
                    @click="clearDefectArea"
                    :disabled="!isCreate"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ImagePreview',
  components: {},
  props: [
    'area',
    'isCreate'
  ],
  data() {
    return {
      defectAreas: [],
      xyCoordinates: [],
      limitDefectArea: 1,
      defectAreaCount: 0,
      imageUrl: "img/top-view-truck.png", // Replace with uploaded image path
      canvas: null,
      ctx: null,
      image: null,
      originalWidth: 0,
      originalHeight: 0,
      base64Image: '',
    }
  },
  methods: {
    hidePreview() {
      this.$parent.showPreview = false
    },
    clearDefectArea() {
        // Clear the canvas and redraw the original image
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.ctx.drawImage(this.image, 0, 0);
    },
    initializeCanvas() {
      // let defectAreaImage = this.getImageFromCache('defectArea_'+this.$parent.selectedDefectType+'.png')
      this.canvas = this.$refs.canvas;
      this.ctx = this.canvas.getContext("2d");
      this.image = new Image();
      // this.renderBase64ToCanvas()
      /*if (defectAreaImage) {
        this.imageUrl = defectAreaImage
      }*/
      this.image.src = this.imageUrl;

      // Load the image and set up canvas
      this.image.onload = () => {
        this.originalWidth = this.image.width;
        this.originalHeight = this.image.height;

        // Set the canvas size to the original image dimensions
        this.canvas.width = this.originalWidth;
        this.canvas.height = this.originalHeight;

        // Draw the image on the canvas
        this.ctx.drawImage(this.image, 0, 0);
      };
    },
    addDot(event) {
      // Get the scaled canvas dimensions
      const rect = this.canvas.getBoundingClientRect();
      const scaleX = this.originalWidth / rect.width; // Scale factor for X
      const scaleY = this.originalHeight / rect.height; // Scale factor for Y

      // Calculate the actual click position
      const x = (event.clientX - rect.left) * scaleX;
      const y = (event.clientY - rect.top) * scaleY;

      // Draw a red dot at the calculated position
      this.ctx.fillStyle = "red";
      this.ctx.beginPath();
      this.ctx.arc(x, y, 10, 0, Math.PI * 2); // Circle with 10px radius
      this.ctx.fill();
    },
    saveImage() {
      // Export canvas as base64 image
      const base64Image = this.canvas.toDataURL("image/png");
      this.$parent.showPreview = false

      this.$parent.renderedUpdatedDefectArea(base64Image)

      let imageName = 'defectArea_'+this.$parent.selectedDefectType+'.png';
      this.saveImageToCache(base64Image, imageName);
    },

    async saveImageToCache(base64Image, imageName) {
      const cache = await caches.open("pwa-image-cache");

      // Convert Base64 to Blob
      const base64Response = await fetch(base64Image);
      const blob = await base64Response.blob();

      // Add the blob to the cache
      const response = new Response(blob);
      await cache.put(imageName, response);

      console.log("Image saved to Cache Storage!");
    },
    async getImageFromCache(imageName) {
      const cache = await caches.open("pwa-image-cache");
      const cachedResponse = await cache.match(imageName);

      if (cachedResponse) {
        const blob = await cachedResponse.blob();
        const base64Image = await this.convertBlobToBase64(blob);
        this.base64Image = base64Image
        // this.renderBase64ToCanvas()
        return base64Image;
        // Use the base64 string as needed
      } else {
        await  this.getDefaultImage()
        // return this.renderBase64ToCanvas();

      }
    },
    async getDefaultImage() {
      const imageUrl = '/img/top-view-truck.png'; // Correct relative path from "public" folder

      try {
        const response = await fetch(imageUrl);

        if (!response.ok) {
          throw new Error(`Network response was not ok (status: ${response.status})`);
        }
        const blob = await response.blob();
        const base64Image = await this.convertBlobToBase64(blob);
        this.base64Image = base64Image;
        return base64Image
      } catch (error) {
        console.error("Error loading default image:", error);
        return null; // Return null or fallback base64 string
      }
    },

    // Helper: Convert Blob to Base64
    convertBlobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    },
    async renderBase64ToCanvas() {
      try {
        const canvas = this.$refs.canvas;
        const ctx = canvas.getContext("2d");

        // Convert base64 to Blob URL
        const blobUrl = await this.convertBase64ToBlobUrl(this.base64Image);

        // Load the Blob URL as an image
        const img = new Image();
        img.onload = () => {
          // Clear the canvas (optional)
          ctx.clearRect(0, 0, canvas.width, canvas.height);

          // Draw the image onto the canvas
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

          // Revoke the Blob URL to release memory
          URL.revokeObjectURL(blobUrl);
        };
        img.onerror = (error) => {
          console.error("Failed to load image from Blob URL", error);
        };

        img.src = blobUrl;
      } catch (error) {
        console.error("Error converting base64 to Blob URL", error);
      }
    },

    async convertBase64ToBlobUrl(base64) {
      const response = await fetch(base64);
      const blob = await response.blob();
      return URL.createObjectURL(blob);
    },
  },
  mounted() {
    this.initializeCanvas();
  }
}
</script>
<style scoped>
.markImage {
  width: 500px !important;
}
.dot {
  font-size: 5em;
  font-weight: bolder;
  color: red;
  z-index: 9999999;
  position: absolute
}
canvas {
  cursor: crosshair;
}
button {
  margin-top: 10px;
  padding: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
}
button:hover {
  background-color: #45a049;
}
</style>